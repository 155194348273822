@import '../../../../scss/theme-bootstrap';

.email-signup-block {
  margin: auto;
  &__title {
    font-family: $font--heading;
    font-size: 14px;
    margin-top: 0;
    font-weight: bold;
    padding-bottom: 5px;
  }
  &__messages {
    text-align: $ldirection;
    padding: 10px 0;
  }
  &__message {
    &--error {
      color: $color-error;
      span {
        display: inline-block;
      }
    }
  }
  &__fields {
    @include pie-clearfix;
    margin: 0 auto;
  }
  input[type='email'].email-signup-block__field {
    @include input-placeholder {
      color: $color-gray;
      text-transform: none;
    }
    color: $color-gray;
    font-size: get-rem(16px);
    font-family: $font--pretendard-regular;
    position: relative;
    border: 0;
    border-bottom: 1px solid $color-dark-gray;
    padding: 10px 0;
    &.error {
      border-bottom: 1px solid $color-error;
    }
  }
  &__submit {
    display: block;
    margin: auto;
    margin-top: -35px;
    position: relative;
    border: none;
    background: transparent;
    float: $rdirection;
    .icon--icon-plane {
      width: 25px;
      height: 25px;
    }
    &.button--disabled {
      .icon--icon-plane {
        fill: $color-dark-gray;
      }
    }
  }
  &__success {
    position: relative;
  }
}
